import React from "react";
import { graphql } from "gatsby"; // Link
// Import components
import Layout from "../components/layout/Layout";
import { getBlockContent, getBlockImage } from "../utils/helpers";
import SpeakerCard from "../components/layout/SpeakerCard";

// Primitives
import Grid from "../components/primitives/Grid";
import EmojiIcon from "../components/primitives/EmojiIcon";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

// Temporarily (?) comment out, not currently used
// const formatSubtext = foredragsholdere => {
//   switch (foredragsholdere.length) {
//     case 0:
//       return "";
//     case 1:
//       return `${foredragsholdere[0].name}`;
//     case 2:
//       return `${foredragsholdere[0].name} and ${foredragsholdere[1].name}`;
//     default:
//       return `${foredragsholdere[0].name}, ${foredragsholdere[1].name}, ...`;
//   }
// };

const Workshops = ({ data }) => {
  const { nodes: workshops = [] } = data.allSanityWorkshop;
  const { pageTitle, shoutOut, metaInfo, title, slug, _rawContent } =
    data?.sanityPages || {};

  const metaStructure = {
    title: metaInfo?.header || title,
    someTitle: metaInfo?.someHeader || pageTitle,
    description:
      metaInfo?.description || getBlockContent(_rawContent) || shoutOut,
    fullSlug: slug?.current,
    image: metaInfo?.image || getBlockImage(_rawContent)?.image,
    imageAlt: metaInfo?.image
      ? metaInfo?.imageAlt
      : getBlockImage(_rawContent)?.alt,
  };

  return (
    <Layout metaInfo={metaStructure}>
      <Grid>
        <div className="col-span-12">
          <h1 className="text-4xl uppercase motion-safe:animate-symbol-bounce-slow">
            {pageTitle} <EmojiIcon name="workshop" animated />{" "}
          </h1>
        </div>
        <div className="col-span-12 lg:col-span-8 xxl:col-span-6">
          {shoutOut && <p className="font-bold">{shoutOut}</p>}
        </div>
        {/* Spacer */}
        <div className="col-span-12 lg:col-span-4 xxl:col-span-6" />

        {workshops.map((workshop, i) => {
          const { foredragsholdere = [], title = "" } = workshop;
          const { image = {} } = foredragsholdere[0] || {};
          const { asset = {} } = image;
          const { url = "" } = asset;
          let positionOnTop = getRandomInt(2) === 1;
          return (
            <div
              className="col-span-12 md:col-span-6 xxl:col-span-4"
              key={workshop + i}
            >
              <SpeakerCard
                title={title}
                name={foredragsholdere[0]?.name}
                url={`/workshop/${workshop.slug.current}`}
                imageUrl={url}
                language={foredragsholdere[0]?.language}
                positionImageOnTop={positionOnTop}
                wordBreak
              />
            </div>
          );
        })}
      </Grid>
    </Layout>
  );
};

export default Workshops;

export const query = graphql`
  query {
    sanityPages(slug: { current: { eq: "workshops2022" } }) {
      id
      pageTitle
      shoutOut
      _rawContent(resolveReferences: { maxDepth: 10 })
      slug {
        current
      }
      metaInfo {
        description
        header
        someHeader
        imageAlt
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityWorkshop(filter: { conferenceYear: { eq: "2022" } }) {
      nodes {
        title
        slug {
          current
        }
        foredragsholdere {
          name
          stilling
          company
          language
          image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;
